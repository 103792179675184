import React from 'react';
import linkwallet from "../../../images/dashboard/finance/linkwallet.png"
import { FaPlus } from "react-icons/fa";

const LinkAccount = () => {



    return (
        <div>
            <div className="w-[1260px] h-[630px] max-w-full mx-auto border border-gray-500 bg-gray-900 rounded-[36px] overflow-hidden overflow-x-hidden">
                {/* Gradient overlay */}
                <div
                    className=""
                    style={{
                        background: '#452A7C1A opacity-50',
                        backgroundBlendMode: 'overlay',
                    }}
                ></div>

                {/* "No Dependant" section */}
                <div className="flex flex-col items-center justify-center h-full">
                    <div className="flex flex-col items-center justify-center h-full">
                        <div
                            className="text-center w-[184px] h-[233px] bg-[#161717] bg-opacity-[20%] 
                            rounded-[29px] p-4 flex flex-col items-center justify-center cursor-pointer"
                        >
                            <div className="inline-flex items-center justify-center w-24 h-24 mb-4">
                                <img src={linkwallet} className="w-full h-full" alt="pending" />
                            </div>
                            <div className="w-[131px] h-[35px] flex flex-col items-center justify-center">
                                <h2 className="text-[9px] font-bold text-[#F4F4F4] leading-[12px]">You don't have any liked accounts</h2>
                                <p className="text-[9px] font-bold text-[#F4F4F4] leading-[11px] text-center">Kindly link the wallet to continue</p>
                            </div>
                        </div>
                        <button
                            className="text-white flex items-center justify-center w-[95px] gap-2 text-[11px] mt-2 font-medium rounded-lg"
                            style={{
                                background: 'linear-gradient(251.21deg, #987C55 0%, #D0B58C 100%)',
                            }}
                        >
                            <FaPlus />Add Wallet
                        </button>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default LinkAccount;